import dayjs from "dayjs"
import { GetServerSidePropsContext } from "next"
import { destroyCookie, parseCookies } from "nookies"

import { JWT_SECRET } from "../../constants/const"
import jwt from "jsonwebtoken"
import { HARISENIN_PUBLIC_LOGO } from "../../constants/pictures"

export function getTimeDifference(time) {
  dayjs.locale("id")

  let timeStamps
  const a = dayjs(time).format("YYYY-MM-DD")
  const b = dayjs(new Date()).format("YYYY-MM-DD")
  const timeA = dayjs(a)
  const timeB = dayjs(b)

  const difference = timeB.diff(timeA, "days")
  if (difference === 0) {
    timeStamps = dayjs(time).format("HH.mm")
  } else {
    timeStamps = difference + "d"
  }

  return timeStamps
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

export function timeDivider(timestamp) {
  if (dayjs(timestamp).isSame(new Date(), "day")) {
    return "Hari ini"
  } else if (dayjs(new Date()).diff(dayjs(timestamp), "day") === 1) {
    return "Kemarin"
  } else {
    return dayjs(timestamp).format("DD MMM")
  }
}

export function formatChecker(file) {
  const imageFormat = [".png", ".jpeg", ".jpg", ".bmp", ".jfif", ".jif"]
  return imageFormat.some((str) => file.includes(str))
}

export function getLabel(option, value) {
  if (!value) {
    return
  }

  const filter = option.filter((item) => item.value === value)
  return filter[0].label
}

export function messageCounter(array) {
  const newArr = []
  for (let index = 0; index < array.length; index++) {
    const arr = array[index]
    newArr.push(arr.comments.length)
  }

  return newArr.reduce((a, b) => a + b)
}

export function domainChecker(): string {
  const isProduction = process.env.NODE_ENV === "production"

  if (isProduction) {
    return process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
  } else {
    return "localhost"
  }
}

export function serverTokenChecker(ctx: GetServerSidePropsContext): string | null {
  const parsedCookies = parseCookies(ctx)
  const token = parsedCookies.HSTOKENID

  if (!token) {
    return null
  }

  jwt.verify(token, JWT_SECRET, (err) => {
    if (err) {
      destroyCookie(ctx, "HSTOKENID", {})
      return null
    }
  })

  return token
}

export function creditCardType(cc: string) {
  function checkSubstring(length: number, match: string) {
    return cc.substring(0, length) === match
  }

  switch (true) {
    case cc[0] === "4":
      return `${HARISENIN_PUBLIC_LOGO}/logo_visa.png`
    // case checkSubstring(2, "34"):
    //   return "AMERICAN EXPRESS"
    case checkSubstring(2, "50"):
    case checkSubstring(2, "51"):
    case checkSubstring(2, "52"):
    case checkSubstring(2, "53"):
    case checkSubstring(2, "54"):
    case checkSubstring(2, "55"):
      return `${HARISENIN_PUBLIC_LOGO}/logo_mastercard.png`
    // case checkSubstring(4, "6011"):
    //   return "DISCOVER"
    // case checkSubstring(3, "300"):
    //   return "DINERS"
    case checkSubstring(2, "35"):
      return `${HARISENIN_PUBLIC_LOGO}/logo_jcb.png`
    default:
      return ""
  }
}

export function creditCardTypeV2(cc: string, getName?: boolean) {
  const cardNumber = cc.replace(/ /g, "")
  const amex = new RegExp("^3[47][0-9]{13}$")
  const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$")
  const cup1 = new RegExp("^62[0-9]{14}[0-9]*$")
  const cup2 = new RegExp("^81[0-9]{14}[0-9]*$")

  const mastercard = new RegExp("^5[1-5][0-9]{14}$")
  const mastercard2 = new RegExp("^2[2-7][0-9]{14}$")

  const disco1 = new RegExp("^6011[0-9]{12}[0-9]*$")
  const disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$")
  const disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$")

  const diners = new RegExp("^3[0689][0-9]{12}[0-9]*$")
  const jcb = new RegExp("^35[0-9]{14}[0-9]*$")

  if (visa.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_visa.png` : "VISA"
  }
  if (amex.test(cardNumber)) {
    return "AMEX"
  }
  if (mastercard.test(cardNumber) || mastercard2.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_mastercard.png` : "MASTERCARD"
  }
  if (disco1.test(cardNumber) || disco2.test(cardNumber) || disco3.test(cardNumber)) {
    return "DISCOVER"
  }
  if (diners.test(cardNumber)) {
    return "DINERS"
  }
  if (jcb.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_jcb.png` : "JCB"
  }
  if (cup1.test(cardNumber) || cup2.test(cardNumber)) {
    return "CHINA_UNION_PAY"
  }
  return undefined
}

export function isInAppChecker(): boolean {
  const nookies = parseCookies()
  const userAgent = nookies["HS_UG"]

  if (!userAgent) {
    return false
  }

  switch (true) {
    case userAgent.includes("Instagram"):
    case userAgent.includes("Twitter"):
    case userAgent.includes("FB_IAB"):
    case userAgent.includes("FBAV"):
    // case userAgent.includes("iPhone"):
    // case userAgent.includes("iPad"):
      return true

    default:
      return false
  }
}
