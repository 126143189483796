import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa"
import React from "react"

export const StarRating = ({ value }: { value: number }) => {
  const rounded = Math.floor(value)
  const element = []

  for (let i = 1; i <= 5; i++) {
    if (i <= rounded) {
      element.push(<FaStar />)
    } else {
      const reduced = Math.abs(value - i)

      if (reduced % 1) {
        element.push(<FaStarHalfAlt />)
      } else {
        element.push(<FaRegStar />)
      }
    }
  }

  return (
    <>
      {element.map((value1, index) => (
        <React.Fragment key={index}>{value1}</React.Fragment>
      ))}
    </>
  )
}
