import Head from "next/head"
import useOpenGraphImage, { useFullUrl } from "../utils/open-graph/graph-image"

export interface HelmetProps {
  title?: string
  description?: string
  image?: string
  isIndexed?: boolean
  canonicalUrl?: string
  schemaMarkup?: any
}

const Helmet = ({ title, description, isIndexed, schemaMarkup  }: HelmetProps) => {
  const { imageURL } = useOpenGraphImage()
  const fullURL = useFullUrl()

  return (
    <Head>
      <title>
        {title || "Harisenin.com: Sekolah online yang membantu mewujudkan karir dan masa depanmu."}
      </title>
      <meta
        name="description"
        content={
          description ||
          `Pelajari keahlian dan rahasia sukses untuk mewujudkan karir dan masa depan impianmu.`
        }
      />
      <meta name="keywords" content="bootcamp, jobs, freelance, digital marketing" />
      <meta name="robots" content="index, follow" />{" "}
      <meta name="googlebot" content={isIndexed ? "index, follow" : "noindex"} />
      <meta name="language" content="Indonesian" />
      <meta name="author" content="harisenin.com" />
      {/*Open Graph / Facebook*/}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullURL} />
      <meta
        property="og:title"
        content={
          title || "Harisenin.com: Sekolah online yang  membantu mewujudkan karir dan masa depanmu."
        }
      />
      <meta
        property="og:description"
        content={
          description ||
          "Pelajari keahlian dan rahasia sukses untuk mewujudkan karir dan masa depan impianmu."
        }
      />
      <meta property="og:image" content={imageURL} />
      <meta property="og:site_name" content="Harisenin, PT Karya Kaya Bahagia" />
      <meta
        property="og:image:alt"
        content={
          title ||
          "Pelajari keahlian dan rahasia sukses untuk mewujudkan karir dan masa depan impianmu."
        }
      />
      {/* Twitter Head */}`
      <meta
        property="twitter:title"
        content={
          title || "Harisenin.com: Sekolah online yang  membantu mewujudkan karir dan masa depanmu."
        }
      />
      <meta
        property="twitter:description"
        content={
          description ||
          "Pelajari keahlian dan rahasia sukses untuk mewujudkan karir dan masa depan impianmu."
        }
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullURL} />
      <meta property="twitter:image" content={imageURL} />
      <meta
        name="twitter:image:alt"
        content={
          title || "Harisenin.com: Sekolah online yang  membantu mewujudkan karir dan masa depanmu."
        }
      />
      <meta property="twitter:site" content="@harisenincom" />
      {/*facebook optional*/}
      <meta property="fb:app_id" content="970446600184538" />
      <meta property="ia:markup_url" content={fullURL} />
      <meta property="ia:markup_url_dev" content={fullURL} />
      <meta property="ia:rules_url" content={fullURL} />
      <meta property="ia:rules_url_dev" content={fullURL} />
      <link rel="canonical" href={fullURL} />
      {Boolean(schemaMarkup?.length) &&
        schemaMarkup?.map((s, i) => (
          <script
            key={i}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                s,
              }),
            }}
          />
        ))}
    </Head>
  )
}

export default Helmet
