import Image from "next/image"
import Helmet from "../lib/helpers/Helmet"

const Error = () => {
  return (
    <>
      <Helmet title="Harisenin.com: Server Error" />
      <div className="error_page">
        <div className="error_page__left">
          <Image
            src="https://nos.jkt-1.neo.id/harisenin-storage/public/assets/pages/error-page_asset.png"
            alt="image"
            width={568}
            height={400}
          />
        </div>
        <div className="error_page__right">
          <Image
            src="https://nos.jkt-1.neo.id/harisenin-storage/public/assets/pages/error-page_status-500.png"
            alt="error 500"
            width={306}
            height={166}
          />
          <h2>Maaf, terjadi kesalahan pada server.</h2>
          <p>
            Mungkin kamu bisa coba hapus cache pada browser atau
            <a href="https://www.harisenin.com">muat ulang halaman ini.</a>
          </p>
        </div>
      </div>
    </>
  )
}

// @ts-ignore
Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
