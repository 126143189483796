import { RefreshTokenBody, SubmitPasswordBody } from "@interfaces/auth"
import KyMethod from "./KyMethod"

export default class AuthRepo extends KyMethod {
  protected async rawLogin({
    user_email,
    user_password,
  }: {
    user_email: string
    user_password: string
  }) {
    return this._crudMethod("/auth/login", { user_email, user_password })
  }

  protected async rawGoogleLogin({
    googleId,
    userFullname,
    email,
  }: {
    googleId: string
    userFullname: string
    email: string
  }) {
    return this._crudMethod("/auth/google", {
      googleId,
      userFullname,
      email,
    })
  }

  protected async rawSubmitGooglePassword(body: SubmitPasswordBody) {
    return this._crudMethod("/auth/submit/password", body)
  }

  protected async rawShowUserLogin() {
    return this._getProtectedData("/auth")
  }

  protected async rawResendVerification(email: string, redirect?: string) {
    return this._protectedCrudMethod("/user/resend-verification", {
      method: "post",
      body: {
        user_email: email,
        redirect,
      },
    })
  }

  protected async rawRefreshToken(body: RefreshTokenBody) {
    return this._protectedCrudMethod("/auth/refresh_tokens", { body })
  }

  protected async rawLogout() {
    return this._protectedCrudMethod("/auth/logout")
  }

  protected async rawGetUserData() {
    return this._getProtectedData("/auth/me")
  }
}
