import { SchoolType } from "@interfaces/school"
import dayjs from "dayjs"
import "dayjs/locale/id"

export function priceFormatter(price: number | string, isNotInt = false) {
  if (!price) {
    return "Rp.0"
  } else if (isNotInt) {
    return `Rp${new Intl.NumberFormat("id-ID").format(parseInt(price as string, 10))}`
  } else {
    return `Rp${new Intl.NumberFormat("id-ID").format(price as number)}`
  }
}

export function textShortener(text, limit) {
  if (!text) {
    return "-"
  }

  if (text.length > limit) {
    return `${text.substr(0, limit)}...`
  } else {
    return text
  }
}

export const getFirstName = (name, noShortener) => {
  if (!name) {
    return ""
  }

  const wordArray = name.split(" ")
  const firstName = wordArray[0]
  if (noShortener === true) {
    return firstName
  }

  if (firstName.length > 7) {
    return textShortener(firstName, 5)
  } else {
    return firstName
  }
}

export function pad(num) {
  let str
  if (num === undefined) {
    str = 0
  } else {
    str = num.toString()
  }
  let newNum
  if (str.length === 1) {
    newNum = str.padStart(2, "0")
  } else {
    newNum = num
  }

  return newNum
}

export function createMarkup(html) {
  return { __html: html }
}

export function programTypeHandler(type: SchoolType) {
  switch (type) {
    case "BOOTCAMP":
      return "Bootcamp"
    case "PRO_CLASS":
      return "ProClass"
    case "CODING_CAMP":
      return "Coding Camp"

    default:
      break
  }
}

export function dateFormatter(date: any, format: string) {
  dayjs.locale("id")
  return dayjs(date).format(`${format}`)
}

export function timeConverter(seconds: number) {
  if (isNaN(seconds)) {
    return `00:00`
  }
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, "0")
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
  }
  return `${mm}:${ss}`
}

export function calculateAge(birthdate: string | Date) {
  const birthDate = new Date(birthdate)
  const currentDate = new Date()

  let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear()
  const monthsDiff = currentDate.getMonth() - birthDate.getMonth()
  const daysDiff = currentDate.getDate() - birthDate.getDate()

  // Check if the birthdate for the current year has already occurred
  if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
    yearsDiff-- // Subtract one year if the birthdate hasn't occurred yet
  }

  return yearsDiff
}