// const normalizeSrc = (src) => {
//   if (!src) {
//     return ""
//   }

//   return src.startsWith("/") ? src.slice(1) : src
// }

export default function cloudflareLoader({ src, width, quality }) {
  if (!src) {
    return ""
  }

  const encodedURI = src?.startsWith("http") ? encodeURIComponent(src) : src
  const params = [`width=${width}`]

  if (quality) {
    params.push(`quality=${quality}`)
  }

  params.push(`image=${encodedURI}`)

  const paramsString = params.join("&")

  if (src?.startsWith("/")) {
    return `${src}?w=${width}&q=${quality || 75}`
  }

  return `https://img.kkbahagia.com?${paramsString}`
}
