import "regenerator-runtime/runtime"
import NProgress from "nprogress"
import Router from "next/router"

import Error from "./_error"

import "../styles/index.scss"
import "react-multi-carousel/lib/styles.css"
import "react-phone-number-input/style.css"
import "nprogress/nprogress.css"
import { useGoogleOneTapLogin } from "@hooks/useGoogleOneTapLogin"
import type { AnalyticsSnippet } from "@segment/analytics-next"
// import { AnalyticsProvider } from "@lib/context/analytics"
import { GoogleOAuthProvider } from "@react-oauth/google"

declare global {
  interface Window {
    analytics: AnalyticsSnippet
  }
}

function App({ Component, pageProps }) {
  // Progress bar on top of screen
  Router.events.on("routeChangeStart", () => {
    NProgress.start()
  })
  Router.events.on("routeChangeComplete", () => {
    NProgress.done()
  })
  Router.events.on("routeChangeError", () => {
    NProgress.done()
  })

  useGoogleOneTapLogin()

  if (pageProps.error) {
    return <Error />
  }

  return (
    // <AnalyticsProvider>
    <GoogleOAuthProvider
      clientId={process.env.GOOGLE_CLIENT_ID || process.env.HARISENIN_GOOGLE_CLIENT_ID || ""}
    >
      <Component {...pageProps} />
    </GoogleOAuthProvider>
    // </AnalyticsProvider>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getInitialProps({ Component, router, ctx }) {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return { pageProps }
}

export default App
