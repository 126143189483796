export const HARISENIN_PUBLIC_S3 = `${
  process.env.CDN_URL || process.env.HARISENIN_CDN_URL
}/public/assets`

export const HARISENIN_PUBLIC_LOGO = `${HARISENIN_PUBLIC_S3}/logos`

export const HARISENIN_PUBLIC_ICON = `${HARISENIN_PUBLIC_S3}/icon`

export const HARISENIN_PUBLIC_PAGE_ASSETS = `${HARISENIN_PUBLIC_S3}/page-assets`

export const HARISENIN_LOGO = `${HARISENIN_PUBLIC_LOGO}/logo_harisenin-white.png`

export const HARISENIN_LOGO_COLORED = `${HARISENIN_PUBLIC_LOGO}/logo_harisenin.svg`

export const HARISENIN_LOGO_WHITE = `${HARISENIN_PUBLIC_LOGO}/logo_harisenin-white-new.png`

export const HARISENIN_EMPTY_PRODUCT = `${HARISENIN_PUBLIC_S3}/placeholders/placeholder_no-product.png`

export const HARISENIN_PLACEHOLDER = `${HARISENIN_PUBLIC_S3}/placeholders/placeholder_image.jpeg`
