import { Method } from "got"

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const fetcher = async (
  url: string,
  options?: {
    method?: Method
    body?: any
    bodyType?: "json" | "form_data"
    isServerSide?: boolean
  }
) => {
  try {
    const dev = process.env.NODE_ENV !== "production"
    const server = dev ? "http://localhost:3001" : process.env.HOME_URL

    const bodyType = options?.bodyType ?? "json"

    let body

    switch (true) {
      case options?.body === undefined:
        body = null
        break
      case bodyType === "json":
        body = JSON.stringify(options?.body)
        break
      case bodyType === "form_data":
        body = options.body
        break
      default:
        break
    }

    const res = await fetch(options?.isServerSide ? server + url : url, {
      method: options?.method ?? "GET",
      ...(bodyType === "json" && {
        headers: {
          "Content-Type": "application/json",
        },
      }),
      body: body,
    })

    if (res.ok) {
      const json = await res.json()
      return {
        response: res,
        data: json,
        ok: res.ok,
      }
    } else {
      if (res) {
        const json = await res.json()
        return {
          data: json ?? {},
          ok: false,
          statusCode: res.status,
        }
      } else {
        return {
          ok: false,
          statusCode: 500,
          data: {},
        }
      }
    }
  } catch (e) {
    return {
      data: [],
      ok: false,
    }
  }
}
