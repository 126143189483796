import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { Time } from "../../interfaces/ui"

export function CountdownTimer(date: Date) {
  dayjs.locale("id")

  const calculateTimeLeft = () => {
    const a = dayjs(date)
    const b = dayjs(new Date())
    const difference = a.diff(b)
    let timeLeft: Time = {
      days: 0,
      hours: 0,
      seconds: 0,
      minutes: 0,
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  return timeLeft
}
