import nookies from "nookies"
import { GoogleInitializeResponse, IGoogleEndPointResponse } from "@interfaces/google"
import jwt from "jsonwebtoken"
import { useEffect } from "react"
import AuthServices from "@services/auth.services"

export function useGoogleOneTapLogin() {
  const cookies = nookies.get({})
  const auth = new AuthServices()

  const handleInitialize = async (data: GoogleInitializeResponse) => {
    const decode = jwt.decode(data.credential) as unknown as IGoogleEndPointResponse

    try {
      const res = await auth.googleLogin({
        email: decode.email,
        fullName: decode.name,
        googleId: decode.sub,
      })

      if (res.isSuccess) {
        window.location.reload()
      } else {
        if (process.env.NODE_ENV === "development") {
          console.log(res)
        }
      }
    } catch (e: any) {
      if (process.env.NODE_ENV === "development") {
        console.log({ e })
      }
    }
  }

  useEffect(() => {
    const token = cookies.HSTOKENID

    if (!window) {
      return
    }

    window?.google?.accounts?.id?.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID || process.env.HARISENIN_GOOGLE_CLIENT_ID || "",
      callback: handleInitialize,
    })

    if (!token) {
      window?.google?.accounts?.id?.prompt()
      return
    }
  }, [])
}
