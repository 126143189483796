import {
  GoogleLoginBody,
  GoogleLoginData,
  GoogleLoginResponse,
  GoogleNewUserResponse,
  LoginManualBody,
  LoginResponse,
  RawTokenData,
  RawUserTraits,
  SubmitPasswordBody,
  UserTraits,
} from "@interfaces/auth"
import { domainChecker } from "@lib/functions"
import { deleteTokenCookies, getTokenInfo, setTokenCookies } from "@lib/functions/auth"
import AuthRepo from "@repositories/auth.repository"
import { destroyCookie, setCookie } from "nookies"
import { Result } from "./response"

export default class AuthServices extends AuthRepo {
  async manualLogin({ email, password }: LoginManualBody) {
    try {
      const res = await this.rawLogin({ user_email: email, user_password: password })

      if (res.ok) {
        const data = await res.json()
        const errorCode = data.error_code

        switch (errorCode) {
          case "REQUIRED":
            this._sendError("Terjadi Kesalahan. Silahkan coba lagi")
            break
          case "WRONG_PASSWORD":
            this._sendError("Password yang kamu masukkan salah")
            break
          case "NOT_FOUND":
            this._sendError("Email belum terdaftar")
            break

          default: {
            const result = this._getResult<LoginResponse>(data)

            const tokenData = setTokenCookies(result)

            if (!tokenData) {
              return Result.fail<{ message: string }>(
                "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
              )
            }

            if (tokenData.data?.status_completed_profile) {
              destroyCookie(null, "HS_TEMP")
            }

            return Result.ok<RawTokenData>(tokenData)
          }
        }
      } else {
        return Result.fail("Terjadi Kesalahan. Silahkan coba lagi")
      }
    } catch (e: any) {
      if (e.statusCode) {
        throw e
      }

      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.data.error_code

      switch (errorCode) {
        case "NOT_VERIFIED":
          throw {
            message: "Akun ini belum diaktifkan. Silahkan cek email kamu untuk mengaktifkan akun",
          }
        case "DEACTIVATED":
          throw {
            message: "Akun ini sudah dinonaktifkan",
          }
        case "SUSPENDED":
          throw {
            message: "Akun ini telah ditangguhkan",
          }

        default: {
          throw { message: "Terjadi kesalahan. Silahkan coba lagi" }
        }
      }
    }
  }

  async googleLogin({ email, fullName, googleId }: GoogleLoginBody) {
    try {
      const res = await this.rawGoogleLogin({ userFullname: fullName, googleId, email })

      const result = await this._getRawResult<GoogleLoginResponse | GoogleNewUserResponse>(res)

      if (!result) {
        return Result.fail<GoogleLoginData | GoogleNewUserResponse>(
          "Terjadi Kesalahan. Silahkan coba lagi"
        )
      }

      if (result.new_user === 0) {
        const loginResult = result as GoogleLoginResponse
        const rawTokenData = setTokenCookies(loginResult)

        if (!rawTokenData) {
          return Result.fail<GoogleLoginData>("Terjadi Kesalahan. Silahkan coba lagi")
        }

        destroyCookie(null, "HS_TEMP")
        const tokenData = rawTokenData.data
        return Result.ok<GoogleLoginData>({
          ...tokenData,
          new_user: 0,
        })
      }

      const loginResult = result as GoogleNewUserResponse
      setCookie(null, "HS_TEMP", JSON.stringify(loginResult), {
        path: "/",
        domain: domainChecker(),
      })

      return Result.ok<GoogleNewUserResponse>(loginResult)
    } catch (e: any) {
      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.errorCode

      switch (errorCode) {
        case "ACCOUNT_HAS_BEEN_DEACTIVATED":
          return Result.fail<GoogleNewUserResponse>("Akun ini sudah dinonaktifkan")
        case "USER_ACCOUNT_SUSPENDED":
          return Result.fail<GoogleNewUserResponse>("Akun ini telah ditangguhkan")
        case "USER_ALREADY_EXISTS":
          return Result.fail<GoogleNewUserResponse>(
            "Email sudah terdaftar, silahkan cek email dan verifikasi akun kamu."
          )

        default: {
          return Result.fail<GoogleNewUserResponse>("Terjadi Kesalahan. Silahkan coba lagi")
        }
      }
    }
  }

  async submitGooglePassword(body: SubmitPasswordBody) {
    try {
      const res = await this.rawSubmitGooglePassword(body)

      const response = await this._getResponse<LoginResponse>(res)

      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<LoginResponse>(
          "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
        )
      }

      setTokenCookies(result)

      destroyCookie(null, "HS_TEMP")
      return Result.ok<LoginResponse>(result)
    } catch (e: any) {
      return Result.fail<LoginResponse>(
        "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
      )
    }
  }

  async resendVerificationEmail(email: string, redirect?: string) {
    try {
      const res = await this.rawResendVerification(email, redirect)

      if (res.ok) {
        const result = await this._getRawResult<any>(res)
        return Result.ok<any>(result)
      } else {
        return Result.fail<any>()
      }
    } catch (e: any) {
      if (process.env.NODE_ENV === "development") {
        console.log(e)
      }

      throw e
    }
  }

  async refreshToken() {
    try {
      const { refreshToken } = getTokenInfo()
      console.log(refreshToken)
      const res = await this.rawRefreshToken({ refresh_token: refreshToken })
      console.log(res)
      const response = await this._getRawResult<any>(res)
      const { result } = response
      console.log(res)

      if (result) {
        setTokenCookies(result)
      } else {
        console.log(result, refreshToken)
      }
    } catch (e: any) {
      return Result.fail()
    }
  }

  async logout() {
    deleteTokenCookies()

    try {
      await this.rawLogout()
    } catch (e: any) {
      return e
    }
  }

  async getUserData() {
    try {
      const res = await this.rawGetUserData()

      if (!res.ok) {
        return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
      }

      const result = await this._getRawResult<RawUserTraits>(res)

      return Result.ok<UserTraits>(result.traits)
    } catch (error) {
      return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
    }
  }
}
